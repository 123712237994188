import React, { Component } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

import LocateControl from './LocateControl';

import L from 'leaflet';

const myCustomColour = '#00733f';

const markerHtmlStyles = `
  background-color: ${myCustomColour};
  width: 2rem;
  height: 2rem;
  display: block;
  left: -1rem;
  top: -1rem;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  border: 2px solid #FFFFFF`
  
  export default class Map extends Component {
    render() {
  
      const position = [47.45, 7.65]
  
      if (typeof window !== 'undefined') {

        /* 2021-09-12: had to move this here for SSR to work */
        const icon = L.divIcon({
          className: "my-custom-pin",
          iconAnchor: [0, 24],
          labelAnchor: [-6, 0],
          popupAnchor: [0, -36],
          html: `<span style="${markerHtmlStyles}" />`
        })        

        return (
  
            <MapContainer center={position} zoom={11} style={{zIndex: 0, height: '700px'}} >
              <TileLayer
              url="https://tile.osm.ch/osm-swiss-style/{z}/{x}/{y}.png"
              attribution='&amp;copy <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'/>
              <LocateControl startDirectly/>   
<Marker icon={icon} position={[47.46610, 7.58517]}><Popup><a href="#aesch-pfeffingen">Aesch-Pfeffingen</a></Popup></Marker>
<Marker icon={icon} position={[47.41582, 7.71652]}><Popup><a href="#arboldswil">Arboldswil</a></Popup></Marker>
<Marker icon={icon} position={[47.49264, 7.62409]}><Popup><a href="#arlesheim">Arlesheim</a></Popup></Marker>
<Marker icon={icon} position={[47.53545254475332, 7.5499452391729545]}><Popup><a href="#binningen">Binningen</a></Popup></Marker>
<Marker icon={icon} position={[47.55806, 7.62936]}><Popup><a href="#birsfelden">Birsfelden</a></Popup></Marker>
<Marker icon={icon} position={[47.45269, 7.51678]}><Popup><a href="#blauen">Blauen</a></Popup></Marker>
<Marker icon={icon} position={[47.40623, 7.64926]}><Popup><a href="#bretzwil">Bretzwil</a></Popup></Marker>
<Marker icon={icon} position={[47.45067, 7.74465]}><Popup><a href="#bubendorf">Bubendorf</a></Popup></Marker>
<Marker icon={icon} position={[47.412761945887425, 7.812474170411499]}><Popup><a href="#diegten">Diegten</a></Popup></Marker>
<Marker icon={icon} position={[47.44244, 7.49683]}><Popup><a href="#dittingen">Dittingen</a></Popup></Marker>
<Marker icon={icon} position={[47.48065, 7.55357]}><Popup><a href="#ettingen">Ettingen</a></Popup></Marker>
<Marker icon={icon} position={[47.50169, 7.69819]}><Popup><a href="#frenkendorf">Frenkendorf</a></Popup></Marker>
<Marker icon={icon} position={[47.51511, 7.73188]}><Popup><a href="#füllinsdorf">Füllinsdorf</a></Popup></Marker>
<Marker icon={icon} position={[47.51491649208127, 7.731491492559619]}><Popup><a href="#füllinsdorf">Füllinsdorf</a></Popup></Marker>
<Marker icon={icon} position={[47.48830, 7.89288]}><Popup><a href="#hemmiken">Hemmiken</a></Popup></Marker>
<Marker icon={icon} position={[47.48780, 7.89318]}><Popup><a href="#hemmiken">Hemmiken</a></Popup></Marker>
<Marker icon={icon} position={[47.46432, 7.78398]}><Popup><a href="#itingen">Itingen</a></Popup></Marker>
<Marker icon={icon} position={[47.42646719425098, 7.759092922905898]}><Popup><a href="#lampenberg">Lampenberg</a></Popup></Marker>
<Marker icon={icon} position={[47.392694036358186, 7.856188043413534]}><Popup><a href="#läufelfingen">Läufelfingen</a></Popup></Marker>
<Marker icon={icon} position={[47.38905, 7.67609]}><Popup><a href="#lauwil">Lauwil</a></Popup></Marker>
<Marker icon={icon} position={[47.48640, 7.75905]}><Popup><a href="#liestal">Liestal</a></Popup></Marker>
<Marker icon={icon} position={[47.51699, 7.61433]}><Popup><a href="#münchenstein">Münchenstein</a></Popup></Marker>
<Marker icon={icon} position={[47.51368, 7.65574]}><Popup><a href="#muttenz">Muttenz</a></Popup></Marker>
<Marker icon={icon} position={[47.41133512185495, 7.755365033535387]}><Popup><a href="#niederdorf">Niederdorf</a></Popup></Marker>
<Marker icon={icon} position={[47.52299, 7.54971]}><Popup><a href="#oberwil">Oberwil</a></Popup></Marker>
<Marker icon={icon} position={[47.46835, 7.87322]}><Popup><a href="#ormalingen">Ormalingen</a></Popup></Marker>
<Marker icon={icon} position={[47.507006176711684, 7.687538787851635]}><Popup><a href="#pratteln">Pratteln</a></Popup></Marker>
<Marker icon={icon} position={[47.44767351317796, 7.765827391683892]}><Popup><a href="#ramlinsburg">Ramlinsburg</a></Popup></Marker>
<Marker icon={icon} position={[47.4887, 7.5706]}><Popup><a href="#reinach / therwil">Reinach / Therwil</a></Popup></Marker>
<Marker icon={icon} position={[47.48073, 7.83808]}><Popup><a href="#rickenbach">Rickenbach</a></Popup></Marker>
<Marker icon={icon} position={[47.43183, 7.88499]}><Popup><a href="#rünenberg">Rünenberg</a></Popup></Marker>
<Marker icon={icon} position={[47.46876240253058, 7.729652226815147]}><Popup><a href="#seltisberg">Seltisberg</a></Popup></Marker>
<Marker icon={icon} position={[47.47165, 7.79907]}><Popup><a href="#sissach">Sissach</a></Popup></Marker>
<Marker icon={icon} position={[47.45503507546162, 7.830595809162761]}><Popup><a href="#thürnen">Thürnen</a></Popup></Marker>
<Marker icon={icon} position={[47.40175, 7.71744]}><Popup><a href="#titterten">Titterten</a></Popup></Marker>
<Marker icon={icon} position={[47.38062, 7.75106]}><Popup><a href="#waldenburg (zusammen mit oberdorf & langenbruck)">Waldenburg (zusammen mit Oberdorf & Langenbruck)</a></Popup></Marker>
<Marker icon={icon} position={[47.43960, 7.90921]}><Popup><a href="#wenslingen">Wenslingen</a></Popup></Marker>
<Marker icon={icon} position={[47.46476, 7.91867]}><Popup><a href="#wenslingen">Wenslingen</a></Popup></Marker>
<Marker icon={icon} position={[47.42583, 7.84161]}><Popup><a href="#wittinsburg">Wittinsburg</a></Popup></Marker>
<Marker icon={icon} position={[47.40924, 7.91507]}><Popup><a href="#zeglingen, kilchberg">Zeglingen, Kilchberg</a></Popup></Marker>
            </MapContainer>
  
        )
      }
      return null
    }
  }

import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';

import OverviewMap from "../components/Map";

/* see https://stackoverflow.com/questions/40365440/react-leaflet-map-not-correctly-displayed */
import 'leaflet/dist/leaflet.css';

const Index = () => (
  <Layout> 
    <section id="uebersicht" className="pt-20 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2 py-5">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Baselbieter Naturschutztag
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Am 26. Oktober 2024 &ndash; mancherorts auch früher oder später &ndash; finden in vielen Baselbieter Gemeinden Einsätze zugunsten der Natur statt.
          </p>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Melden Sie bitte Ihren Anlass mit untenstehendem Formular. Wir werden auf dieser Seite alle gemeldeten Anlässe zusammen mit einer Übersichtskarte publizieren. Die Publikation erfolgt nicht automatisch, sondern nach manueller Prüfung.
          </p>
           <p className="text-xl lg:text-2xl mt-6 font-light">
            Finden Sie in der untenstehenden Übersicht einen Anlass in Ihrer Nähe und packen Sie mit an!
          </p>       
        </div>
        <div className="text-center lg:text-left lg:w-1/2 px-5">
          <img className="w-full rounded border-2" src="Heckenpflanzung.jpg"/>
        </div>        
      </div>
    </section>

     <section id="karte" className="pt-20 md:pt-20">
      <div className="container mx-auto text-center">
      <OverviewMap />
      </div>
    </section>

{/* Beispiel: https://stackoverflow.com/questions/67417275/cards-of-same-height-in-tailwind-css */}
{/* Manual: https://tailwindcss.com/docs/grid-template-columns */}    

<section id="anlaesse" className="pt-20 md:pt-20">
      <div className="container mx-auto text-center">
<h2 className="text-3xl lg:text-5xl font-semibold mb-12">Gemeldete Anlässe</h2>
        {/* <p className="text-xl lg:text-2xl mt-6 font-light">
        Die gemeldeten Anlässe werden in Kürze aufgeschaltet.
        </p> */}
{/*         <p className="text-xl lg:text-2xl mt-6 font-light mb-12">
        Das nächste Update der gemeldeten Anlässe erfolgt am 18. September 2023.
        </p>  */}    
        <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">

<Card className="" id="aesch-pfeffingen">
                     <p className="font-semibold text-xl">Aesch-Pfeffingen</p><p className="mt-4">26. Oktober 2024, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Bachmattweg beim Chlusbach in Aesch (s. Koordinaten auf der Karte)</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Patrick Schaub, <a href="tel:077 434 16 56">077 434 16 56</a>, <a href="mailto:pschaub@vtxmail.ch">pschaub@vtxmail.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Vogelschutzverein Aesch-Pfeffingen</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Bauen eines Sandariums (Sandlinse für Wildbienen)
Jäten der bestehenden Sandlinse
Mähen der Wiese und Zusammenrechen des Schnittgutes
Allenfalls kürzen / zurückschneiden von Sträuchern in der Hecke</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Es hat Arbeit für jung und alt! Wir freuen uns auf eure Mithilfe.</p></Card>
<Card className="" id="arboldswil">
                     <p className="font-semibold text-xl">Arboldswil</p><p className="mt-4">26. Oktober 2024, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Dorfplatz Grand Place</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Christoph Tschopp, <a href="tel:061 931 28 80">061 931 28 80</a>, <a href="mailto:chris.tschopp@eblcom.ch">chris.tschopp@eblcom.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> BNV-Sektion</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Forstbetriebe Frenkentäler</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Waldrandpflege</p></Card>
<Card className="" id="arlesheim">
                     <p className="font-semibold text-xl">Arlesheim</p><p className="mt-4">26. Oktober 2024, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Bachrechen Eingang Ermitage</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Susanne Bollinger, <a href="tel:079 774 55 14">079 774 55 14</a>, <a href="mailto:nva@nvarlesheim.ch">nva@nvarlesheim.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Naturschutzverein Arlesheim</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Verschiedene Pflegearbeiten in den Naturschutzgebieten des ehemaligen Steinbruchs oberhalb des Dorfes und im Widenacker neben der Birs.</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Im Anschluss gibt es ein Mittagessen.</p></Card>
<Card className="" id="binningen">
                     <p className="font-semibold text-xl">Binningen</p><p className="mt-4">19. Oktober 2024, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Gerätehaus Naturschutzgebiet Herzogenmatt</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Benedikt Schmidt, <a href="tel:078 719 69 16">078 719 69 16</a>, <a href="mailto:benedikt.schmidt@uzh.ch">benedikt.schmidt@uzh.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Verein Freunde der Herzogenmatt</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Diverse Pflegearbeiten (Weiher, Gehölz, Wiesen)</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> <a href="http://www.herzogenmatt.ch/arbeitseinsaetze">www.herzogenmatt.ch/arbeitseinsaetze</a></p></Card>
<Card className="" id="birsfelden">
                     <p className="font-semibold text-xl">Birsfelden</p><p className="mt-4">26. Oktober 2024, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Am Froschbrunnen im NSG "Biotop Am Stausee"</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Judith Roth, <a href="tel:077 498 35 33">077 498 35 33</a>, <a href="mailto:judith@biotop-birsfelden.ch">judith@biotop-birsfelden.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Vogelschutzverein Birsfelden</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Teichumlandpflege, Heckenrückschnitte, Arbeiten an der Trockenmauer, Steinhaufe, etc.</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Mitbringen: Der Witterung angepasste Kleidung, gutes Schuhwerk und Arbeitshandschuhe.

<strong> Anmeldung</strong>: Bitte melde dich bis zum Donnerstag, 24. Oktober 2024 bei uns hier an <a href="http://www.biotop-birsfelden.ch/anmeldung-anlass/,">www.biotop-birsfelden.ch/anmeldung-anlass/,</a> damit wir die Verpflegung besser organisieren können. Danke.

Natürlich dürfen sich auch noch Kurzentschlossene am Samstag einfinden.</p></Card>
<Card className="" id="blauen">
                     <p className="font-semibold text-xl">Blauen</p><p className="mt-4">26. Oktober 2024, 08.30 - 16.00 Uhr</p><p className="mt-0 mb-4">Schützenhaus Blauen</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Fabian Meury, <a href="tel:079 447 14 35">079 447 14 35</a>, <a href="mailto:meury.fabian@gmail.com">meury.fabian@gmail.com</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Burgerrat Blauen</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Natur- und Vogelschutzverein Blauen-Dittingen-Nenzlingen, Gemeinderat, Aktion zämmeläbe-zämmerede-zämmeschaffe.</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Zurückschneiden und Pflege der Weide Blauen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Der Anlass findet bei jedem Wetter statt
Dem Wetter entsprechende Kleidung, festes Schuhwerk, Arbeitskleidung, Handschuhe. 
Wenn vorhanden Heugabel, Rechen, Sense usw. mitbringen.
Das Mittagessen wird offeriert.</p></Card>
<Card className="" id="bretzwil">
                     <p className="font-semibold text-xl">Bretzwil</p><p className="mt-4">26. Oktober 2024, 08.30 - 12.00 Uhr</p><p className="mt-0 mb-4">Jägerhüttli</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Karin Mühlberg, <a href="tel:079 416 88 65">079 416 88 65</a>, <a href="mailto:k.muehlbi@bluewin.ch">k.muehlbi@bluewin.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Umweltkommission Bretzwil, Natur- und Vogelschutzverein Bretzwil</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Aufschichten von Asthaufen, Erstellen Steinhaufen für Wiesel</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Für das leibliche Wohl ist gesorgt!</p></Card>
<Card className="" id="bubendorf">
                     <p className="font-semibold text-xl">Bubendorf</p><p className="mt-4">26. Oktober 2024, 08.30 - 13.30 Uhr</p><p className="mt-0 mb-4">Parkplatz Vitaparcours Bubendorf</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Thomas Aebischer, <a href="tel:079 667 92 82">079 667 92 82</a>, <a href="mailto:info@nvvb.ch">info@nvvb.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Vogelschutzverein Bubendorf</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Einwohner- und Bürgergemeinde sowie Landschaftsschutzkommission</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Heckenpflege und setzen von Hochstammobstbäumen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Auch Kinder sind herzlich willkommen</p></Card>
<Card className="" id="diegten">
                     <p className="font-semibold text-xl">Diegten</p><p className="mt-4">26. Oktober 2024, 08.30 - 12.30 Uhr</p><p className="mt-0 mb-4">Schulhaus Imbermatt</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Thomas Mumenthaler, <a href="tel:079 311 55 76">079 311 55 76</a>, mumenthaler-<a href="mailto:gartenbau@bluewin.ch">gartenbau@bluewin.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Verschönerungsverein Diegten</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Schule Jagdgesellschaft Forst</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Teich ausschneiden, Neophyten bekämpfen, Insektenhotel bauen, Eiben schützen.</p></Card>
<Card className="" id="dittingen">
                     <p className="font-semibold text-xl">Dittingen</p><p className="mt-4">26. Oktober 2024, 08.30 - 15.30 Uhr</p><p className="mt-0 mb-4">Schulhausplatz, Schulweg 2, 4243 Dittingen</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Céline Jermann, <a href="tel:079 850 30 29">079 850 30 29</a>, <a href="mailto:verwaltung@bk-dittingen.ch">verwaltung@bk-dittingen.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Burgerkorporation Dittingen</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Forst, Segelfluggruppe etc.</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Wir werden einerseits die Weide im Bereich Chilchebode – Chatzestäge – Egg pflegen und andererseits Lebensräume für Kleintiere gestalten.

Mitbringen: Dem Wetter und Anlass entsprechende Kleidung, Handschuhe und wer hat, gerne Hacke/Pickel.

Nach dem Arbeitseinsatz treffen wir uns gegen 14:30 Uhr zum gemeinsamen Mittagessen und gemütlichen Beisammensein im Schemelschopf. Für das leibliche Wohl ist gesorgt😊</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Wir freuen uns auf zahlreiche Helferinnen und Helfer von gross bis klein😊 
Es erleichtert uns die Organisation, wenn du dich vorab anmeldest. 
<strong> Anmeldung</strong> bis 18.10.2024 unter: <a href="http://www.bk-dittingen.ch/naturschutztag">www.bk-dittingen.ch/naturschutztag</a> 

Selbstverständlich sind auch spontan entschlossene Unterstützerinnen und Unterstützer herzlich willkommen.
</p></Card>
<Card className="" id="ettingen">
                     <p className="font-semibold text-xl">Ettingen</p><p className="mt-4">26. Oktober 2024, 13.30 - 17.00 Uhr</p><p className="mt-0 mb-4">beim Platz des Fasnachtsfeuers auf dem Rebhügel</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Peter Brodmann, <a href="tel:079 716 64 07">079 716 64 07</a>, <a href="mailto:peter.a.brodmann@gmail.com">peter.a.brodmann@gmail.com</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Naturschutzverein Ettingen (NSVE)</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Landwirt, Blauenbiker, Jungschar Ettingen&Therwil</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Durchforstung der Hecke zum Lerchenflug. Nach den Vorarbeiten durch den NSVE in Zusammenarbeit mit dem Landwirt, der das Umfeld der Hecke bewirtschaftet, werden die vielen anfallenden Äste zusammentragen und zu Haufen aufgeschichtet.</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Die Veranstaltung findet bei jeder Witterung statt. Ausrüstung: Arbeitshandschuhe, festes Schuhwerk und geeignete Kleidung.
Nach dem Arbeitseinsatz laden wir zu einem Zvieri   ein.</p></Card>
<Card className="" id="frenkendorf">
                     <p className="font-semibold text-xl">Frenkendorf</p><p className="mt-4">26. Oktober 2024, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Bei den Rischweihern</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Lisebeth Häring, <a href="tel:079 320 21 98">079 320 21 98</a>, <a href="mailto:info@nvf-frenkendorf.ch">info@nvf-frenkendorf.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> NVF Frenkendorf</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Pflegearbeiten rund um die Weiher und am Bachgehölz</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Aktuelle Angaben unter nvf-frenkendorf.ch</p></Card>
<Card className="" id="füllinsdorf">
                     <p className="font-semibold text-xl">Füllinsdorf</p><p className="mt-4">26. Oktober 2024, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Beim Banntagsparkplatz (Giebenacherhöhe) Füllinsdorf</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Daniela Schmidhauser, <a href="tel:079 365 94 51">079 365 94 51</a>, <a href="mailto:daniela.schmidhauser@eblcom.ch">daniela.schmidhauser@eblcom.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Naturschutzkomission und NVVF</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Heckenpflege am Steinlesehaufen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> <strong> Anmeldung</strong> nötig an: <a href="mailto:bauverwaltung@fuellinsdorf.ch">bauverwaltung@fuellinsdorf.ch</a></p></Card>
<Card className="" id="füllinsdorf">
                     <p className="font-semibold text-xl">Füllinsdorf</p><p className="mt-4">26. Oktober 2024, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Parkplatz (Banntagsplatz) Giebenacherstrasse</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Dominik Lüscher, <a href="tel:079 209 52 06">079 209 52 06</a>, <a href="mailto:dluescher@gmx.li">dluescher@gmx.li</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Vogelschutzverein und Naturschutzkommission</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Heckenpflege, Freilegen Steinlesehaufen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Handschuhe und Heckenschere mitnehmen</p></Card>
<Card className="" id="hemmiken">
                     <p className="font-semibold text-xl">Hemmiken</p><p className="mt-4">26. Oktober 2024, 08.30 - 16.30 Uhr</p><p className="mt-0 mb-4">Schulhausplatz</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Alfred Sutter, <a href="tel:111 111 11 11">111 111 11 11</a>, <a href="mailto:info@hemmiken.ch">info@hemmiken.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Bürgergemeinde, Natur- und Vogelschutzverein</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Einwohnergemeinde</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Waldputztag, Pflege Kleinstrukturen/Bänkli</p></Card>
<Card className="" id="hemmiken">
                     <p className="font-semibold text-xl">Hemmiken</p><p className="mt-4">26. Oktober 2024, 08.30 - 17.00 Uhr</p><p className="mt-0 mb-4">Hemmiken, Dorfplatz</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Elisabeth Scholer, <a href="tel:079 252 82 73">079 252 82 73</a>, <a href="mailto:richilisa.scholer@gmail.com">richilisa.scholer@gmail.com</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Vogelschutzverein/Bürgergemeinde</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Bürgergemeinde</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Kleinstrukturen pflegen/Gasleitungen freilegen/Waldränder zurückschneiden</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> findet bei jedem Wetter statt</p></Card>
<Card className="" id="itingen">
                     <p className="font-semibold text-xl">Itingen</p><p className="mt-4">26. Oktober 2024, 09.00 - 12.30 Uhr</p><p className="mt-0 mb-4">Werkhof Brüschigasse, Itingen</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Rahel Plattner, <a href="tel:061 922 20 93">061 922 20 93</a>, <a href="mailto:rahel@plattnerweb.ch">rahel@plattnerweb.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> UEK Itingen</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Natur- und Vogelschutzverein Itingen</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Arbeiten bei verschiedenen Weihern, Bäume pflanzen, Nistkasten reinigen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Anschliessend (ab 12.30 Uhr) Mittagessen :-)</p></Card>
<Card className="" id="lampenberg">
                     <p className="font-semibold text-xl">Lampenberg</p><p className="mt-4">26. Oktober 2024, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Gemeindeverwaltung, Hauptstrasse 40, 4432 Lampenberg</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Christina Boschi, <a href="tel:410 628 42 21">410 628 42 21</a>, <a href="mailto:cristina.boschi@wieselnetz.ch">cristina.boschi@wieselnetz.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Verein Natur WB-Tal, Cristina Boschi, Sepp Nussbaumer</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Sepp Nussbaumer</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Bau von vier Wieselburgen und einem Steinhaufen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Cristina Boschi wird uns am Naturschutztag Infos über die Wiesel geben und Anweisungen über den Bau der Wieselburgen geben.</p></Card>
<Card className="" id="läufelfingen">
                     <p className="font-semibold text-xl">Läufelfingen</p><p className="mt-4">19. Oktober 2024, 08.30 - 13.00 Uhr</p><p className="mt-0 mb-4">Werkhof beim Bahnhof Läufelfingen</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Christina Spühler, <a href="tel:079 892 44 14">079 892 44 14</a>, <a href="mailto:martins7@bluewin.ch">martins7@bluewin.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Forst, Natur-und Vogelschutz, Jagd, Konfirmanden und Private</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Schützenswerte Matten rechen, Heckenpflege und Weiherpflege. Amphibienschutz</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Mittagessen im Ebnet vom NVL offeriert. <strong> Anmeldung</strong> erwünscht</p></Card>
<Card className="" id="lauwil">
                     <p className="font-semibold text-xl">Lauwil</p><p className="mt-4">26. Oktober 2024, 09.30 - 11.30 Uhr</p><p className="mt-0 mb-4">Mehrzweckhalle Lauwil</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Christine Bader, <a href="tel:077 461 84 62">077 461 84 62</a>, <a href="mailto:praesidium@nvrl.ch">praesidium@nvrl.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur-und Vogelschutzverein Reigoldswil-Lauwil</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Pflege der Engi (Gras rechen und wegtragen)</p></Card>
<Card className="" id="liestal">
                     <p className="font-semibold text-xl">Liestal</p><p className="mt-4">26. Oktober 2024, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Windentalerhöhe, Hof Windental (Bushaltestelle Leisenberg, Linie 72)</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Simone Avila, <a href="tel:077 403 00 14">077 403 00 14</a>, <a href="mailto:simone.avila@nvliestal.ch">simone.avila@nvliestal.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> NV Liestal</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Baum-/Strauchpflegeaktion</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Anschliessend kleine Verpflegung, <strong> Anmeldung</strong> erwünscht</p></Card>
<Card className="" id="münchenstein">
                     <p className="font-semibold text-xl">Münchenstein</p><p className="mt-4">2. November 2024, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Werkhof Münchenstein (Pumpwerkstrasse 7)</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Marina Vegh, <a href="tel:061 416 13 09">061 416 13 09</a>, <a href="mailto:marina.vegh@muenchenstein.ch">marina.vegh@muenchenstein.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Freiraum- und Naturschutzkommission Münchenstein</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Werkhof, Fischerverein, Jagdgesellschaft, Natur- und Vogelschutzverein, Bürgergemeinde, Forst</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Pflegemassnahmen und Neupflanzungen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Bitte gutes Schuhwerk und dem Wetter angepasste Kleidung mitnehmen. Werkzeug wird bereitgestellt und die Teilnehmenden erhalten eine Verpflegung.</p></Card>
<Card className="" id="muttenz">
                     <p className="font-semibold text-xl">Muttenz</p><p className="mt-4">26. Oktober 2024, 08.30 - 12.00 Uhr</p><p className="mt-0 mb-4">Lättehus</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Stephan Brenneisen, <a href="tel:079 326 06 70">079 326 06 70</a>, <a href="mailto:bres@zhaw.ch">bres@zhaw.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Naturschutzverein Muttenz</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Pflegearbeiten Lättehus und im Rebberg</p></Card>
<Card className="" id="niederdorf">
                     <p className="font-semibold text-xl">Niederdorf</p><p className="mt-4">26. Oktober 2024, 09.00 - 14.00 Uhr</p><p className="mt-0 mb-4">Beim Bürgerschopf an der Lampenbergerstrasse</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Helene Koch-Schmutz, <a href="tel:076 502 65 67">076 502 65 67</a>, <a href="mailto:helene.koch@niederdorf.ch">helene.koch@niederdorf.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Umweltschutzkommission</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Verein "gesunde Frenke" (Fischereiverein Niederdorf)</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Weiherpflege, Heckensaum schneiden, Biodiversitäts-Asthaufen bilden, Adlerfarn entfernen und "Bachputzete", Hecke mit Sträuchern ergänzen.</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Arbeiten werden dem Wetter angepasst. Entsprechende Kleidung und gute Schuhe werden empfohlen. Rebscheren und ä gueti Lune erwünscht. :-) 
Versicherung ist Sache der Teilnehmer.
Im Anschluss gibt es ein gratis Mittagessen.</p></Card>
<Card className="" id="oberwil">
                     <p className="font-semibold text-xl">Oberwil</p><p className="mt-4">19. Oktober 2024, 10.00 - 16.00 Uhr</p><p className="mt-0 mb-4">Farradparkplatz Gymnasium Oberwil</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Roland Steiner, <a href="tel:061 401 09 57">061 401 09 57</a>, <a href="mailto:roland.steiner@nvoberwil.ch">roland.steiner@nvoberwil.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Vogelschutzverein Oberwil</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Gemeinde Oberwil</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Bau von Kleinstrukturen wie Vieselburg, Verstecke und Nisthilfen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Das Mittagessen wird offeriert, bitte Geschirr und Besteck mitbringen.</p></Card>
<Card className="" id="ormalingen">
                     <p className="font-semibold text-xl">Ormalingen</p><p className="mt-4">26. Oktober 2024, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Hinter der Turnhallte Ormalingen</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Denise Bussinger, <a href="tel:078 753 67 41">078 753 67 41</a>, <a href="mailto:nvvormalingen@gmx.ch">nvvormalingen@gmx.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Vogelschutzverein Ormalingen</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Pflege Naturschutzgebiet Bodenmatt</p></Card>
<Card className="" id="pratteln">
                     <p className="font-semibold text-xl">Pratteln</p><p className="mt-4">19. Oktober 2024, 08.15 - 12.00 Uhr</p><p className="mt-0 mb-4">Talweiher Pratteln</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Martin Classen, <a href="tel:061 825 21 11">061 825 21 11</a>, <a href="mailto:Martin.Classen@Pratteln.ch">Martin.Classen@Pratteln.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Einwohnergemeinde Pratteln</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Naturschutzkommission</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Pflegearbeiten um den Weiher</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Siehe auch Ankündigung auf <a href="http://www.pratteln.ch">www.pratteln.ch</a></p></Card>
<Card className="" id="ramlinsburg">
                     <p className="font-semibold text-xl">Ramlinsburg</p><p className="mt-4">26. Oktober 2024, 08.30 - 12.00 Uhr</p><p className="mt-0 mb-4">Mehrzweckhalle Ramlinsburg</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Doreth Strübin, <a href="tel:079 412 06 12">079 412 06 12</a>, <a href="mailto:vn.ramlinsburg@gmail.com">vn.ramlinsburg@gmail.com</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Verein für Naturschutz Ramlinsburg in</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Schnittgut zusammen nehmen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Anschliessend wird ein Mittagessen offeriert.</p></Card>
<Card className="" id="reinach / therwil">
                     <p className="font-semibold text-xl">Reinach / Therwil</p><p className="mt-4">26. Oktober 2024, 08.30 - 12.30 Uhr</p><p className="mt-0 mb-4">Erlenhof</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Fabio di Pietro, <a href="tel:079 292 16 12">079 292 16 12</a>, <a href="mailto:fabio_di_pietro@hotmail.com">fabio_di_pietro@hotmail.com</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Verein für Natur- und Vogelschutz Reinach</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Neue Gelbbauchunken-Standorte bauen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Gartenhandschuhe, Gartenschere, Klappsäge wenn vorhanden mitbringen. Znüni und Zmittag offeriert</p></Card>
<Card className="" id="rickenbach">
                     <p className="font-semibold text-xl">Rickenbach</p><p className="mt-4">26. Oktober 2024, 13.00 - 17.00 Uhr</p><p className="mt-0 mb-4">Schützenhaus Rickenbach</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Mathias Oberer, <a href="tel:079 383 55 16">079 383 55 16</a>, <a href="mailto:mathias.oberer@natur-in-rickenbach.ch">mathias.oberer@natur-in-rickenbach.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur in Rickenbach</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Div. Pflegemassnahmen am Waldweiher, Ergänzung des Nistkastenparks, Neophytenbekämpfung an verschiedenen Stellen.</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Tragen Sie gutes Schuhwerk und Arbeitshandschuhe. Bringen Sie, wenn vorhanden, eine Baumschere mit. Im Anschluss offerieren wir ein Zvieri</p></Card>
<Card className="" id="rünenberg">
                     <p className="font-semibold text-xl">Rünenberg</p><p className="mt-4">19. Oktober 2024, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Werkhof</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Urs Wolfsberger, <a href="tel:079 470 71 33">079 470 71 33</a>, <a href="mailto:wolfsberger@bluewin.ch">wolfsberger@bluewin.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur am Wisenberg, Einwohner- und Bürgergemeinde</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Forstrevier Homburg, Jagdgesellschaft Rünenberg-Kilchberg</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Pflege Waldrand, Pflege Weihermatt, Pflege Chrindelmatte</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Nach getaner Arbeit gibt es etwas vom Grill, Getränke und Kaffee und Kuchen.</p></Card>
<Card className="" id="seltisberg">
                     <p className="font-semibold text-xl">Seltisberg</p><p className="mt-4">9. November 2024, 08.30 - 11.30 Uhr</p><p className="mt-0 mb-4">Heime auf Berg, Rebhaldenstr. 25, Seltisberg</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Tabea Haupt, <a href="tel:076 725 20 47">076 725 20 47</a>, <a href="mailto:tabea.haupt@hauptsachnatur.ch">tabea.haupt@hauptsachnatur.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur- und Vogelschutzverein Seltisberg</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Heime auf Berg</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Hilfe beim Weiherbau, Setzen von Sträuchern und Bäumen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Findet bei garstigem Wetter nicht statt.</p></Card>
<Card className="" id="sissach">
                     <p className="font-semibold text-xl">Sissach</p><p className="mt-4">26. Oktober 2024, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Bei den Scheunen in der Abzweigung Limberg - Alpbad oberhalb des Schrebergartens areals Grienmatt in Sissach</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Lina Ferrari, <a href="tel:077 525 83 86">077 525 83 86</a>, <a href="mailto:lina.ferrari@protonmail.ch">lina.ferrari@protonmail.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Arbeitsgemeinschaft für Natur und Heimatschutz Sissach AGNHS</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Komission Landschaft der Gemeinde Sissach</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> In Sissach stehen dieses Jahr Baumpflanzungen am Rebberg, die Erweiterung/Lückenschliessung einer bestehenden Hecke und die Entfernung eines nicht mehr benötigten Zaunes am Waldrand auf dem Programm. Es wird also mehrere Arbeitsfelder geben, an denen jeweils in Gruppen gearbeitet werden kann.</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Gutes Schuhwerk und wetterangepasste Kleidung ist erforderlich. Der Arbeitseinsatz dauert bis am Mittag, anschliessend sind alle Helfenden herzlich zu einem gemeinsamen Mitagessen eingeladen.</p></Card>
<Card className="" id="thürnen">
                     <p className="font-semibold text-xl">Thürnen</p><p className="mt-4">23. November 2024, 08.00 - 15.00 Uhr</p><p className="mt-0 mb-4">Beim Brunnen in der Rebgasse (beim Altersheim)</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Mario Flückiger, <a href="tel:079 607 04 30">079 607 04 30</a>, <a href="mailto:info@thuernen.bl.ch">info@thuernen.bl.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Bügerkollegium (Büko) Thürnen</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Waldarbeiten</p></Card>
<Card className="" id="titterten">
                     <p className="font-semibold text-xl">Titterten</p><p className="mt-4">26. Oktober 2024, 09.00 - 14.00 Uhr</p><p className="mt-0 mb-4">Dorfplatz/Bushaltestelle Gemeindehaus</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Enrico Leuzinger, <a href="tel:079 966 68 57">079 966 68 57</a>, <a href="mailto:enrico.leuzinger@kit.edu">enrico.leuzinger@kit.edu</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> NVVT</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Amphibienteiche pflegen</p></Card>
<Card className="" id="waldenburg (zusammen mit oberdorf & langenbruck)">
                     <p className="font-semibold text-xl">Waldenburg (zusammen mit Oberdorf & Langenbruck)</p><p className="mt-4">26. Oktober 2024, 09.15 - 15.00 Uhr</p><p className="mt-0 mb-4">Zivilschutzanlage Gerstel, Waldenburg</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Beat Feigenwinter, <a href="tel:061 961 86 53">061 961 86 53</a>, <a href="mailto:beat.feigenwinter@bluewin.ch">beat.feigenwinter@bluewin.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur-, Umwelt- und Landschaftsschutzkommission (NULS) Waldenburg</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Natur- und Umweltkommission Oberdorf
Kommission Natur & Landschaft Langenbruck
Gemeinderäte Waldenburg, Oberdorf, Langenbruck
Jugendabteilung FC Oberdorf
Jagdgesellschaft Oberdorf</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Heckenpflege
Waldrandpflege
Baumpflanzungen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Weitere Details zum Anlass in der Oberbaselbieterzeitung Rubrik Gemeindenachrichten Waldenburg</p></Card>
<Card className="" id="wenslingen">
                     <p className="font-semibold text-xl">Wenslingen</p><p className="mt-4">26. Oktober 2024, 09.00 - 13.00 Uhr</p><p className="mt-0 mb-4">Dorfladen Wenslingen</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Regula Walnder, <a href="tel:061 991 00 58">061 991 00 58</a>, <a href="mailto:info@naturschutzwenslingen.ch">info@naturschutzwenslingen.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Naturschutzverein Wenslingen</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Erzmattweiher: Schilfschnitt; Fotzelbrünnli (Weiher) putzen, Efeubaum mit Kleinstrukturen im Schulbaumgarten erstellen, Nistkästen umhängen</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> mit Kurzführung zu den Naturaufwertungen rund um den Barmenhof</p></Card>
<Card className="" id="wenslingen">
                     <p className="font-semibold text-xl">Wenslingen</p><p className="mt-4">26. Oktober 2024, 13.00 - 17.00 Uhr</p><p className="mt-0 mb-4">13 Uhr Werkhof der Bürgergemeinde, Hegmatt 1, 4467 Rothenfluh</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Martin Küng, <a href="tel:079 295 84 67">079 295 84 67</a>, <a href="mailto:marc@tubel.net">marc@tubel.net</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur-und Vogelschutzverein Rothenfluh-Anwil-Oltingen</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Familie Buser, Barmenhof, Wenslingen</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Heckensträucher pflanzen, Heckenpflege</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> weiterer Treffpunkt: 13:10 Uhr Schulhaus Oltingen. Siehe auch Einladung auf <a href="http://www.nuvrao.ch">www.nuvrao.ch</a></p></Card>
<Card className="" id="wittinsburg">
                     <p className="font-semibold text-xl">Wittinsburg</p><p className="mt-4">26. Oktober 2024, 08.30 - 12.30 Uhr</p><p className="mt-0 mb-4">Gemeindescheune</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Jörg Läubli, <a href="tel:079 508 19 58">079 508 19 58</a>, <a href="mailto:joerg.laeubli@bluewin.ch">joerg.laeubli@bluewin.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Einwohnergemeinde</p></Card>
<Card className="" id="zeglingen, kilchberg">
                     <p className="font-semibold text-xl">Zeglingen, Kilchberg</p><p className="mt-4">12. Oktober 2024, 09.00 - 12.00 Uhr</p><p className="mt-0 mb-4">Gipsgrube Zeglingen</p><p className="mt-2 text-xs text-left"><strong>Kontakt:</strong> Urs Wolfsberger, <a href="tel:079 470 71 33">079 470 71 33</a>, <a href="mailto:wolfsberger@bluewin.ch">wolfsberger@bluewin.ch</a></p><p className="mt-2 text-xs text-left"><strong>Veranstalter:</strong> Natur am Wisenberg, Einwohner- und Bürgergemeinden</p><p className="mt-2 text-xs text-left"><strong>Weitere Beteiligte:</strong> Forst, Jagdgesellschaft</p><p className="mt-2 text-xs text-left"><strong>Arbeiten:</strong> Aufräumarbeiten, Waldrandpflege, Unterhalt Naturlehrpfad</p><p className="mt-2 text-xs text-left"><strong>Bemerkungen:</strong> Ein Znüni und Mittagessen wird von den Bürgergemeinden offeriert.</p></Card>

        </div>
      </div>
</section>

    <section id="melden" className="container mx-auto my-20 py-20 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Anlass melden</h3>
      <p className="mt-8 text-xl font-light">
        Bitte benutzen Sie das Formular, um Ihren geplanten Anlass oder Änderungen zu melden:
      </p>
       <p className="mt-8">
        <Button size="xl" onclick="window.location.href = 'https://form.jotform.com/222343551424347';">zum Formular</Button>
      </p> 

      
    </section> 
  </Layout>
);

export default Index;
